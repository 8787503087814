import React, { memo } from "react";

import {
  MatchBorder,
  MatchContainer,
  MatchMainInfo,
} from "@/game-tft/CommonComponents.jsx";
import MetaData from "@/game-tft/MetaData.jsx";
import ProBuildsPlacementInfo from "@/game-tft/ProBuildsPlacementInfo.jsx";
import ProBuildsPlayerInfo from "@/game-tft/ProBuildsPlayerInfo.jsx";
import Traits from "@/game-tft/Traits.jsx";

function Tile({
  matchid,
  placement,
  name,
  region,
  wins,
  losses,
  ranks,
  patch,
  createdAt,
  companionImage,
  children,
  profileIconId,
  traits,
  isOutOfDate,
  set,
}) {
  const [_platform, scrubbedMatchId] = matchid.split("_");
  return (
    <MatchContainer
      href={`/tft/match/${region}/${name}/${scrubbedMatchId}/scoreboard`}
      companion={companionImage}
      className={isOutOfDate ? "out-of-date" : ""}
    >
      <div className="bg-companion" />

      <MatchMainInfo>
        <MatchBorder placement={placement} />

        <div className="player">
          <ProBuildsPlayerInfo
            profileIconId={profileIconId}
            summonerName={name}
            region={region}
            ranks={ranks}
          />
        </div>

        <div className="placement">
          <ProBuildsPlacementInfo
            placement={placement}
            wins={wins}
            losses={losses}
          />
        </div>

        <div className="traits">
          <Traits traits={traits} isOutOfDate={isOutOfDate} set={set} />
        </div>

        <div className="content">{children}</div>

        <div className="metadata">
          <MetaData patch={patch} createdAt={createdAt} />
        </div>
      </MatchMainInfo>
    </MatchContainer>
  );
}

export default memo(Tile);
