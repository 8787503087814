import React, { memo } from "react";
import { styled } from "goober";
import { tablet } from "clutch/src/Style/style.mjs";

import PostMatchScoreboardMatchTraitList from "@/game-tft/PostMatchScoreboardMatchTraitList.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import TraitsBuildDetails from "@/game-tft/TraitsBuildDetails.jsx";
import { classNames } from "@/util/class-names.mjs";

function TraitsComponent({
  traits,
  set,
  isOutOfDate,
  onlyTraitsList,
  onlyBuildDetails,
  className,
}) {
  if (isOutOfDate) return null;
  const sortedTraits = StaticTFT.sortTraits(traits, set);

  return (
    <Traits {...classNames(className)}>
      {onlyBuildDetails || (
        <div className={"flex"}>
          <PostMatchScoreboardMatchTraitList
            traits={sortedTraits}
            set={set}
            max={4}
            size={24}
          />
        </div>
      )}
      {onlyTraitsList || <TraitsBuildDetails traits={traits} set={set} />}
    </Traits>
  );
}

export default memo(TraitsComponent);

const Traits = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &.desktop {
    ${tablet} {
      display: none;
    }
  }
`;
