import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { MetaDataContainer } from "@/game-tft/CommonComponents.jsx";
import { TimeAgo } from "@/shared/Time.jsx";

function MetaData({ patch, createdAt }) {
  const { t } = useTranslation();
  return (
    <MetaDataContainer>
      <p className="type-caption" style={{ color: "var(--shade3)" }}>
        <TimeAgo date={createdAt} />
      </p>
      {patch ? (
        <p className="type-caption">
          {t("lol:patchVersion", "Patch {{version}}", {
            version: patch,
          })}
        </p>
      ) : null}
    </MetaDataContainer>
  );
}

export default memo(MetaData);
