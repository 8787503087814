import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { GameRecord, PlacementTitle } from "@/game-tft/CommonComponents.jsx";
import { titleColor } from "@/game-tft/constants.mjs";

function ProBuildsPlacementInfo({ placement, wins, losses }) {
  const { t } = useTranslation();
  return (
    <PlacementInfoContainer>
      <PlacementTitle
        className="type-body2-form--active"
        style={{ color: titleColor(placement) }}
      >
        {t("common:ordinalPlace", "{{place, ordinal}} Place", {
          place: placement,
        })}
      </PlacementTitle>
      {wins !== undefined && losses !== undefined ? (
        <GameRecord className="type-caption">
          {t("tft:winsAndLosses", "{{wins}}W - {{losses}}L", {
            wins,
            losses,
          })}
        </GameRecord>
      ) : null}
    </PlacementInfoContainer>
  );
}

const PlacementInfoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
`;

export default memo(ProBuildsPlacementInfo);
