import React, { memo } from "react";
import { styled } from "goober";

import { SERVICES_TO_REGIONS } from "@/game-lol/constants/constants.mjs";
import getRegionIcon from "@/game-lol/utils/get-region-icon.mjs";

const RegionText = styled("p")`
  color: var(--shade2);
  margin-left: var(--sp-1_5);
`;

function Region({ region }) {
  const regionProper = region?.toLowerCase();
  const RegionIcon = getRegionIcon(SERVICES_TO_REGIONS[regionProper]);
  return (
    <RegionStyles>
      <RegionIcon className="region-icon" />
      <RegionText className="type-caption">
        {SERVICES_TO_REGIONS[regionProper]}
      </RegionText>
    </RegionStyles>
  );
}

const RegionStyles = styled("div")`
  display: flex;
  .region-icon {
    width: var(--sp-4);
    height: var(--sp-4);
  }
`;

export default memo(Region);
