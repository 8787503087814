import React from "react";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImage from "@/game-tft/ChampionImage.jsx";
import { MatchChampion } from "@/game-tft/CommonComponents.jsx";
import { ITEM_BLACKLIST } from "@/game-tft/constants.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import UnitAvatarTier from "@/game-tft/UnitAvatarTier.jsx";
import SelectIcon from "@/inline-assets/tft-select.svg";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MatchUnit = ({
  tooltip,
  tier,
  set,
  unitName = "",
  itemKeys = [],
  chosen,
}) => {
  const state = useSnapshot(readState);
  const championsStaticData = state.tft[set]?.champions;
  const itemsStaticData = state.tft[set]?.items;
  const skinSetting = state.settings.tft.isSkinsEnabled;
  const champion = championsStaticData?.[unitName];
  const setItems = itemsStaticData;
  if (!champion) return null;

  return (
    <MatchChampion data-tip={tooltip}>
      <ChampionLevelApi>
        <UnitAvatarTier tier={tier} />
      </ChampionLevelApi>
      {chosen ? <SelectStyledIcon /> : null}
      <ChampionImage
        colorlessBorder
        champKey={champion.apiKey}
        cost={champion?.cost}
        size={32}
        set={set}
        skinSetting={skinSetting}
        chosen={chosen}
      />
      {itemKeys.length && setItems ? (
        <PieceItems>
          {itemKeys.map((item, i) => {
            const unitItem = setItems[item];
            if (ITEM_BLACKLIST.includes(item) || !unitItem) return null;
            return (
              <img
                src={StaticTFT.getItemImage(item, set)}
                key={`${item}_${i}`}
                alt={unitItem.key}
              />
            );
          })}
        </PieceItems>
      ) : null}
    </MatchChampion>
  );
};

export default MatchUnit;

const ChampionLevelApi = styled("div")`
  position: absolute;
  top: calc(var(--sp-4) * -1);
  left: 50%;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  z-index: 2;
`;
const PieceItems = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: var(--sp-0_5);

  img {
    height: auto;
    align-self: center;
    &:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-of-type {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    width: var(--sp-3);
    border: var(--sp-px) solid var(--shade5);
  }
`;
const SelectStyledIcon = styled(SelectIcon)`
  position: absolute;
  z-index: 2;
  right: calc(-1 * var(--sp-0_5));
  top: -5px;
  width: 0.825rem;
`;
