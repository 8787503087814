import React, { memo } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import {
  ProBuildsChampion,
  ProBuildsChampionEmpty,
  ProBuildsChampionsList,
  ProBuildsToolTipItem,
  ProBuildsToolTipName,
} from "@/game-tft/CommonComponents.jsx";
import { CHAMP_BLACKLIST } from "@/game-tft/constants.mjs";
import MatchUnit from "@/game-tft/MatchUnit.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import UnitAvatarTier from "@/game-tft/UnitAvatarTier.jsx";
import orderBy from "@/util/order-array-by.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MatchUnitList = ({ units = [], set }) => {
  const state = useSnapshot(readState);
  const { t } = useTranslation();

  const championsStaticData = state.tft[set]?.champions;
  const itemsStaticData = state.tft[set]?.items;
  const setItems = itemsStaticData;

  const unitsLength = units.length;
  const neededEmptyPieces = unitsLength > 9 ? 0 : 9 - unitsLength;

  units = units?.filter(
    (unit) => !CHAMP_BLACKLIST(set).includes(unit?.character_id),
  );

  return (
    <ProBuildsChampionsList>
      {orderBy(units, "rarity", "asc").map((unit, i) => {
        const { character_id, tier, itemNames: itemKeys, name } = unit;
        const flatItems = Array.isArray(itemKeys)
          ? itemKeys.flat(Infinity)
          : [];
        const unitName = character_id || name;

        const champion = championsStaticData?.[unitName] || {
          key: unitName,
          apiKey: unitName,
          name: unitName,
        };
        const pieceTooltip = ReactDOMServer.renderToStaticMarkup(
          <>
            <ProBuildsToolTipName>
              <p className="type-body1">{champion.name}</p>
              <UnitAvatarTier tier={tier} />
            </ProBuildsToolTipName>
            {flatItems.map((item, i) => {
              const unitItem =
                setItems &&
                Object.values(setItems).find(
                  (setItem) => setItem.apiKey === item,
                );

              if (unitItem) {
                return (
                  <ProBuildsToolTipItem key={`${item}_${i}`}>
                    <img
                      src={StaticTFT.getItemImage(item, set)}
                      alt={unitItem.key}
                    />
                    <p className="type-caption">{unitItem.name}</p>
                  </ProBuildsToolTipItem>
                );
              }
              return (
                <ProBuildsToolTipItem key={`unknownItem_${i}`}>
                  <img
                    src=""
                    alt={t("tft:common.unknownItem", "Unknown Item")}
                  />
                  <p className="type-caption">
                    {t("tft:common.unknownItem", "Unknown Item")}
                  </p>
                </ProBuildsToolTipItem>
              );
            })}
          </>,
        );

        return (
          <MatchUnit
            key={`${unitName}_${i}`}
            unitName={unitName}
            itemKeys={flatItems}
            tooltip={pieceTooltip}
            tier={tier}
            set={set}
          />
        );
      })}

      {new Array(neededEmptyPieces).fill(undefined).map((_, key) => (
        <ProBuildsChampion key={key}>
          <ProBuildsChampionEmpty />
        </ProBuildsChampion>
      ))}
    </ProBuildsChampionsList>
  );
};

export default memo(MatchUnitList);
